const sendLogError = async (requestError = '', methodName = '') => {

    
    let objError = {
        methodName: methodName,
        timestamp: new Date().toISOString(),
        requestError: requestError
    };

    try {
        const response = await fetch('/api/logs', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(objError),
        });

        if (!response.ok) {
            throw new Error(`Failed to send log: ${response.statusText}`);
        }
    } catch (err) {
        console.error('Error sending log:', err);
    }
};

export default sendLogError;

