
/* COMPONENT IMPORTS*/
import ProductDetails from '../../components/ProductDetails/ProductDetails';
import authentication from "../../msalb2creact";
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import { withRouter } from 'react-router';
import React, { Component } from 'react';

/* SAME FOLDER IMPORTS*/

/* CSS IMPORT */
import "./tablestyling.css";

class SavingsNewItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /**startDateBFTeaser: null,
            endDateBFTeaser: null,
            startDateBF: null,
            endDateBF: null,
            actualDate: null,**/
            //actualDateAVOA: null,
            //startDateAVOA: null,
            //endDateAVOA: null,
            //flagAVOA: false
        }
    }

    componentDidMount() {
        //this.GetBlackWeekDates();
        /*this.getAVOApopup();*/
    }

    createSubscription(item) {
        var data = {
            subscriptionID: null,
            subscriptionSerieID: null,
            canReapply: false,
            canReinforce: false,
            /**startDateBFTeaser: null,
            endDateBFTeaser: null,
            startDateBF: null,
            endDateBF: null,
            actualDate: null**/
        };
        this.props.history.push({
            pathname: '/subscricao',
            state: { params: data, modalidade: item }
        })
    }

    render() {
       
        return (
            <React.Fragment>
                {
                    this.props.savingsNewList.filter(item => item.subscriptionsNumber == 0).map((item, index) =>
                        <div key={index} className="card-new" >
                            <table className="card-new-table">
                                <tbody>
                                    <tr>
                                        <th className="card-new-title">
                                            <ProductDetails productID={item.productID} subscriptionType="product" productName={item.productName} subscriptionActive={false} />
                                        </th>
                                        <th style={{ "width": "155px", "paddingLeft": "20px" }}><div className="card-new-table-value">0 €</div>
                                            <div className="tableCapitalCerto-subtitle-unactive">Montante entregue</div>
                                        </th>
                                        <th style={{ "width": "205px", "paddingLeft": "10px" }}><div className="tableCapitalCerto-boldvalue">-</div>
                                            <div className="tableCapitalCerto-subtitle-unactive">Rendimento acumulado Bruto</div>
                                        </th>
                                        <th>
                                            <div className="create-subscription-button" onClick={this.createSubscription.bind(this, item)}>< img src={require('../../images/newseries.png')} style={{ "cursor": "pointer" }} height="50px" width="50px"></img></div>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    )
                }
            </React.Fragment >
        );
    }

    /**async GetBlackWeekDates() {

        const atoken = authentication.getAccessToken();

        const headers = { Authorization: `Bearer ${atoken}` };

        const response = await fetch("/Elections/GetBlackWeekDates", {

            headers,

        });

        if (response.status !== 200) {

        } else {

            const data = await response.json();


            this.setState({
                actualDate: data.actualDate,
                startDateBFTeaser: data.startDateBFTeaser,
                endDateBFTeaser: data.endDateBFTeaser,
                startDateBF: data.startDateBF,
                endDateBF: data.endDateBF,
            });
        }

    }**/

    //async getAVOApopup() {

    //    const atoken = authentication.getAccessToken();
    //    const headers = { Authorization: `Bearer ${atoken}` };
    //    const response = await fetch("/Elections/GetAVOADates", {
    //        headers,

    //    });

    //    if (response.status !== 200) {

    //    } else {

    //        const data = await response.json();


    //        this.setState({
    //            actualDateAVOA: data.actualDateAVOA,
    //            startDateAVOA: data.startDateAVOA,
    //            endDateAVOA: data.endDateAVOA,
    //            flagAVOA: data.flagAVOA
    //        });

    //    }

    //}

}

SavingsNewItem.contextType = ContextConstructor;

export default withRouter(SavingsNewItem);