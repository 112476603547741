/* COMPONENT IMPORTS*/
import { GlossaryPopup } from "../../components/Glossary/GlossaryPopup";
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import NotificationBanner from "../../components/Notifications/NotificationBanner";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { Highlight } from "../../components/Highlights/Highlight";
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import Popup from "reactjs-popup";
import React, { Component } from "react";

/* SAME FOLDER IMPORTS*/
import TableProtSavSubscriptions from "./TableProtSavSubscriptions";
import TableSubscriptions from "./TableSubscriptions";
import sendLogError from "../../sendLogError";

/* CSS IMPORT */
import "./Protecao.css";
import "../../components/Loading/loading.css";

const pageid = "14597";
const browserHistory = createBrowserHistory({ basename: "Protection Page" });
var reactPlugin = new ReactPlugin();

class Protecao extends Component {
  static displayName = Protecao.name;

  constructor(props) {
    super(props);
    this.state = {
      protecao: [],
      error: [],
      showFirstPopup: true,
      showSecondPopup: false,
      selectedOption: "",
      loading: true,
      manhaSelected: true,
      tardeSelected: false,
      sortSelect: "Maior Poupança",
    };
  }

  componentDidMount() {
    if (
      this.context.selectedUserID !== "" &&
      this.context.selectedUserID !== undefined &&
      this.context.selectedUserID !== null
    ) {
      this.populateProtectionData();
    }
  }

  changePopup() {
    var x = this.state.showFirstPopup;
    this.setState({
      showFirstPopup: !x,
      showSecondPopup: x,
    });
  }

  closeSecondPopup() {
    this.setState({
      showSecondPopup: false,
      manhaSelected: true,
      tardeSelected: false,
    });
  }

  sendContactRequestFunction() {
    if (this.state.manhaSelected) var selectedOption = "Manhã";
    else var selectedOption = "Tarde";
    this.sendContactRequest(selectedOption);
    this.setState({
      manhaSelected: true,
      tardeSelected: false,
    });
  }

  errorLoadingPortfolio() {
    return (
      <div>
        <ErrorPage />
      </div>
    );
  }

  handleOptionChange() {
    this.setState({
      manhaSelected: !this.state.manhaSelected,
      tardeSelected: !this.state.tardeSelected,
    });
  }

  customCheckBoxStructure() {
    var manhaBox = this.state.manhaSelected ? (
      <div className="radiobutton-montepio-selected radiobutton-font">
        <img src={require("../../images/selected.png")} height="26px" width="26px"></img>Manhã
      </div>
    ) : (
      <div
        className="radiobutton-montepio radiobutton-font"
        onClick={() => {
          this.handleOptionChange();
        }}>
        <img src={require("../../images/notselected.png")} height="26px" width="26px"></img>Manhã
      </div>
    );
    var tardeBox = this.state.tardeSelected ? (
      <div
        className="radiobutton-montepio-selected radiobutton-font"
        style={{ marginLeft: "34px" }}>
        <img src={require("../../images/selected.png")} height="26px" width="26px"></img>Tarde
      </div>
    ) : (
      <div
        className="radiobutton-montepio radiobutton-font"
        onClick={() => {
          this.handleOptionChange();
        }}
        style={{ marginLeft: "34px" }}>
        <img src={require("../../images/notselected.png")} height="26px" width="26px"></img>Tarde
      </div>
    );

    return (
      <div
        className="contacto-select-boxes-wrapper"
        style={{ display: "inline-flex", marginBottom: "25px" }}>
        {manhaBox}
        {tardeBox}
      </div>
    );
  }

  popupSubscribe(state) {
    if (!state.showSecondPopup) {
      return (
        <Popup
          trigger={
            <img
              className="create-protection-button"
              src={require("../../images/newseries.png")}></img>
          }
          contentStyle={{ background: "none", border: "none" }}
          modal>
          {(close) => (
            <div className="contact-window">
              <center style={{ marginLeft: "auto", marginRight: "auto" }}>
                <img
                  src={require("../../images/phone.png")}
                  style={{ marginBottom: "33px" }}
                  height="100px"
                  width="100px"></img>
                <div className="contact-window-maintext">
                  Será contactado por um gestor em breve.
                </div>
                <div className="contact-botton-wrapper">
                  <img
                    src={require("../../images/cancel.png")}
                    style={{ cursor: "pointer" }}
                    onClick={close}
                    height="40px"
                    width="165px"></img>
                  <img
                    className="button-continuar"
                    src={require("../../images/continuar.png")}
                    onClick={() => this.changePopup()}
                    style={{ marginLeft: "31px", cursor: "pointer" }}
                    height="40px"
                    width="165px"></img>
                </div>
              </center>
            </div>
          )}
        </Popup>
      );
    }
    if (state.showSecondPopup == true) {
      return (
        <Popup
          trigger={
            <img
              className="create-protection-button"
              src={require("../../images/newseries.png")}></img>
          }
          contentStyle={{ background: "none", border: "none" }}
          modal
          closeOnDocumentClick={false}>
          {(close) => (
            <div className="contact-window-2">
              <center className="center-div-second-popup">
                <img
                  src={require("../../images/success.png")}
                  style={{ marginBottom: "33px" }}
                  height="100px"
                  width="100px"></img>
                <div className="contact-window-maintext">
                  Vamos contactá-lo nas próximas 24 horas. Obrigado.
                </div>
                <div className="contact-window-subtext">
                  Escolha o período em que deseja ser contactado
                </div>
                {this.customCheckBoxStructure()}
                <center className="contact-botton-wrapper">
                  <img
                    src={require("../../images/cancel.png")}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      close();
                      this.closeSecondPopup();
                    }}
                    height="40px"></img>
                  <img
                    className="button-continuar"
                    src={require("../../images/confirm.png")}
                    style={{ marginLeft: "31px", cursor: "pointer" }}
                    onClick={() => {
                      this.sendContactRequestFunction();
                      close();
                      this.closeSecondPopup();
                    }}
                    height="40px"></img>
                </center>
              </center>
            </div>
          )}
        </Popup>
      );
    }
  }

  renderProtectionData(state) {
    return (
      <main className="main">
        <NotificationBanner />
        <div className="title-bar">
          <div className="title">Proteção</div>
          <div className="title-glossary">
            <GlossaryPopup page="protecao" />
          </div>
        </div>
        <TableSubscriptions protectionList={state.protecao.protection} />
        <TableProtSavSubscriptions protectionsaveList={state.protecao.savingsPlusProtection} />

        <div className="card-new-protection">
          <table className="card-new-protection-content">
            <tbody>
              <tr>
                <th className="new-series-title">Subscrição de modalidade</th>
                <th rowSpan="2">{this.popupSubscribe(state)}</th>
              </tr>
              <tr>
                <td className="new-series-subtitle">
                  Agende um contacto para dar seguimento ao processo de subscrição
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="new-protecao-area-mobile">
          <div className="new-protecao-title">Subscrever nova modalidade</div>
          <div className="new-protecao-label">
            Aumente a sua proteção. Indique-nos a modalidade que gostava de subscrever que entramos
            em contacto em 24 horas
          </div>
          <div className="card-new-protection card-new-protection-mobile">
            <div className="card-new-protection-content">
              <div>
                <div className="new-series-title">Subscrição de modalidade</div>
              </div>
              <div>
                <div className="new-series-subtitle">
                  Agende um contacto para dar seguimento ao processo de subscrição
                </div>
              </div>
              <div className="create-protection-button-wrapper">{this.popupSubscribe(state)}</div>
            </div>
          </div>
        </div>
        <Highlight page="protecao" />
      </main>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <div className="main-page-loading">
        <div className="loading">
          <img src={require("../../images/loading.svg")}></img>
        </div>
      </div>
    ) : this.state.error ? (
      this.errorLoadingPortfolio()
    ) : (
      this.renderProtectionData(this.state)
    );

    return <div>{contents}</div>;
  }

  async populateProtectionData() {
    var idToken = this.context.idToken;
    var clientId = this.context.selectedUserID;
    //console.log("Protecao clientId ", clientId, " context ", this.context)

    const headers = {
      Authorization: `Bearer ${idToken}`,
      clientId: clientId,
      Accept: "application/json, text/plain",
      "Content-Type": "application/json;charset=UTF-8",
    };

    await fetch(`/Portfolio/GetPortfolio`, {
      headers,
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        this.setState({
          error: false,
          protecao: response,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status != 200) {
          this.setState({
            error: true,
            loading: false,
          });
            sendLogError(error.message, "populateProtectionData");
        }
      });
  }

  async sendContactRequest(option) {
    try {
      //Get Profile
      var idToken = this.context.idToken;
      var clientId = this.context.selectedUserID;
      const headers = { Authorization: `Bearer ${idToken}` };

      const responseManager = await fetch("/Profile/GetManager?ClientId=" + clientId, { headers });

      var dataManager = "";
      if (responseManager.status == 200) {
        dataManager = await responseManager.json();
      } else {
        dataManager = {
          employeeNumber: "",
          email: "Suporte_MyMontepio@montepio.org",
        };
      }

      var clientId = this.context.selectedUserID;

      const headersI = {
        Authorization: `Bearer ${idToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const response = await fetch("/Intentions/CreateIntention", {
        headers: headersI,
        method: "POST",
        body: JSON.stringify({
          IntentionType: "contactrequest_protection",
          EmployeeNumber: dataManager.employeeNumber,
          EmployeeEmail: dataManager.email,
          ContactPreference: option,
          ClientID: clientId,
        }),
      });
    } catch (error) {
        console.error(error);
        sendLogError(error.message, "sendContactRequest");
    }
  }
}

Protecao.contextType = ContextConstructor;

export default withAITracking(reactPlugin, Protecao);
