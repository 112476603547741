/* MODULE IMPORTS */
import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import "./CampanhaReformaPopup.css";

class CampanhaReforma extends Component {
    static displayName = CampanhaReforma.name;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="popup-campanha">
                <div className="popup-content">
                    <div className="popup-image">
                        <img
                            src={require("../../images/carolImage.png")}
                            alt="Woman in orange sweater"
                        />
                    </div>
                    <div className="wrap-popup-btt">
                        <div className="popup-campanha-text">
                            <h2>
                                Poupar para<br />a reforma?
                            </h2>
                            <p>
                                Até 31 de dezembro de 2024, subscreva a modalidade{' '}
                                <span className="highlight">
                                    Montepio Poupança Reforma
                                </span>{' '}
                                ou a modalidade{' '}
                                <span className="highlight">
                                    Montepio Pensões de Reforma
                                </span>,
                                junto do seu Gestor Mutualista, ou reforce também aqui a sua Montepio Poupança Reforma,
                                pelo valor mínimo de subscrição desta modalidade (100€), e receba um Cartão Presente Repsol, no valor de 15€.
                            </p>
                            <p>
                                Consulte os{' '}
                                <NavLink
                                    to={{ pathname: "https://www.montepio.org/wp-content/uploads/2024/10/TC_Reforma_2024_31out.pdf" }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="terms-link"
                                >
                                    Termos e Condições {' '}
                                </NavLink>
                                desta ação.
                            </p>
                            <button
                                onClick={() => {
                                    this.props.close();
                                }}
                                style={{ cursor: "pointer" }}
                                className="popup-campanha-fechar-center"
                            >
                                Fechar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CampanhaReforma;
