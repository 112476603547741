/* COMPONENT IMPORTS*/
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";
import authentication from "../../msalb2creact";
import ReactTable from "react-table-v6";
import Pagination from "../Beneficios/Pagination.js";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import NotificationBanner from "../../components/Notifications/NotificationBanner";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import ContextConstructor from "../../components/Context/Context";

/* MODULE IMPORTS */
import React, { Component } from "react";

/* CSS IMPORT */
import "./Correio.css";
import "../../components/Loading/loading.css";
import "../Beneficios/Pagination.css";
import "../../components/ErrorPage/ErrorPage.css";
import sendLogError from "../../sendLogError";

const browserHistory = createBrowserHistory({ basename: "Documents Page" });
var reactPlugin = new ReactPlugin();

class Correio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingDocument: false,
      mailList: [],
      Sort: "1",
      sortSelect: "Data (mais recentes)",
      noContent: false,
    };
  }

  async componentDidMount() {
    //console.log("Correio Context ", this.context);

    if (
      this.context.selectedUserID !== "" &&
      this.context.selectedUserID !== undefined &&
      this.context.selectedUserID !== null
    ) {
      this.populateData();
    }
  }

  errorLoading() {
    return (
      <div>
        <ErrorPage />
      </div>
    );
  }

  handleChangeDropdown = (event) => {
    switch (event.target.value) {
      case "updown":
        this.setState({ Sort: "1" });
        break;
      case "downup":
        this.setState({ Sort: "2" });
        break;
    }
  };

  setSort(value) {
    var a;
    switch (value) {
      case "1":
        a = "Data (mais recentes)";
        break;
      case "2":
        a = "Data (mais antigos)";
        break;
    }
    this.setState({
      Sort: value,
      sortSelect: a,
    });
  }

  noContent() {
    return (
      <div className="main">
        <center>
          <img
            style={{ marginBottom: "30px", marginTop: "25%" }}
            src={require("../../images/email.png")}
            alt=""
            width="100px"
            height="100px"></img>
          <div className="error-title" style={{ "font-size": "24px" }}>
            Ainda não recebeu correspondência digital.
          </div>
        </center>
      </div>
    );
  }

  renderMailListTable(mailList) {
    const columns = [
      {
        Header: "",
        accessor: "isRead",
        minWidth: 30,
        maxWidth: 60,
        Cell: (row) => (
          <img
            style={{ width: "20px" }}
            src={require("../../images/" + (row.value ? "mailOpened" : "mailUnopened") + ".png")}
          />
        ),
      },
      {
        Header: "",
        accessor: "date",
        minWidth: 60,
        maxWidth: 90,
        className: "mail-date-wrapper",
        Cell: (row) => (
          <div className="mail-date">
            {new Intl.DateTimeFormat("pt-PT").format(new Date(row.value))}
          </div>
        ),
      },
      {
        Header: "",
        accessor: "title",
        Cell: (row) => <div className="mail-name">{row.value}</div>,
      },
      {
        Header: "",
        accessor: "",
        minWidth: 30,
        maxWidth: 60,
        Cell: (row) => (
          <div>
            <img style={{ width: "40px" }} src={require("../../images/mailArrow.png")} />
            <a
              id={"downloadMail" + row.original.documentCode}
              download={row.original.title + ".pdf"}
              target="_blank"
              downloadControl={row.original.documentCode}></a>
          </div>
        ),
      },
    ];

    const sortMethodUpdown = [
      {
        id: "date",
        desc: true,
      },
    ];

    const sortMethodDownUp = [
      {
        id: "date",
        desc: false,
      },
    ];

    return (
      <ReactTable
        defaultSorted={this.state.Sort == "1" ? sortMethodUpdown : sortMethodDownUp}
        data={mailList}
        columns={columns}
        resizable={false}
        nextText=">"
        previousText="<"
        sortable={false}
        PaginationComponent={Pagination}
        defaultPageSize={this.state.mailList.length <= 4 ? this.state.mailList.length : 4}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e) => {
              //if the download href is already filled will just download, if not, it will get the content and then will download
              const id = rowInfo.original.documentCode;
              const documentClass = rowInfo.original.documentClass;
              const documentDate = rowInfo.original.date;

              /** const clientID = rowInfo.original.clientID;
                        const associateNumber = rowInfo.original.associateNumber;
                        const associateControlDigit = rowInfo.original.associateControlDigit;**/

              let elementId = "downloadMail" + id;
              let hrefContent = document.getElementById(elementId).href;
              let testeValue = document.getElementById(elementId).getAttribute("downloadControl");

              if (testeValue == id) {
                this.getMailContent(documentDate, id, documentClass);
              } else {
                document.getElementById("downloadMail" + rowInfo.original.documentCode).click();
              }

              //if it is unopened it will become opened

              if (rowInfo.original.isRead !== true) {
                this.markDocumentAsRead(rowInfo, state);
              }
            },
            style: {
              color: rowInfo ? (rowInfo.row.isRead ? "#6D6E71" : "#373737") : "#373737",
              cursor: "pointer",
              borderBottom: rowInfo ? "1px solid #DDDDDD" : "0px",
            },
          };
        }}
      />
    );
  }

  updateTableReadState(rowInfo, state) {
    //the current value will be updated without refresh
    state.data[rowInfo.index].isRead = true;

    this.setState({
      data: state.data,
      loading: false,
    });
  }

  renderListagemCorreio(mailList) {
    var loading = this.state.loadingDocument ? (
      <div className="document-page-document-loading">
        <div className="loading loading-document">
          <img src={require("../../images/loading.svg")}></img>
        </div>
      </div>
    ) : (
      <div></div>
    );

    return (
      <main className="main">
        <NotificationBanner />
        <div className="title-bar title-bar-mail">
          <div className="title">Correio Digital</div>
        </div>
        <div className="mail-list-wrapper" style={{ position: "relative" }}>
          <div className="mail-list-sort">
            <div className="filter-dropdown">
              <div className="dropdown-title">Ver Por</div>
              <UncontrolledDropdown>
                <DropdownToggle className="dropdown-benefits-button">
                  {this.state.sortSelect}
                </DropdownToggle>
                <DropdownMenu className="dropdown-benefits-menu">
                  <DropdownItem
                    className={
                      this.state.Sort == "1"
                        ? "dropdown-benefits-option selected"
                        : "dropdown-benefits-option"
                    }
                    onClick={this.setSort.bind(this, "1")}>
                    Data (mais recentes)
                  </DropdownItem>
                  <DropdownItem
                    className={
                      this.state.Sort == "2"
                        ? "dropdown-benefits-option selected"
                        : "dropdown-benefits-option"
                    }
                    onClick={this.setSort.bind(this, "2")}>
                    Data (mais antigos)
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          <div className="mail-list">{this.renderMailListTable(mailList)}</div>
          {loading}
        </div>
      </main>
    );
  }

  renderListagemCorreioVazia() {
    return (
      <main className="main">
        <div className="no-mail-div">
          <img className="no-mail-img" src={require("../../images/email.png")}></img>
          <div className="no-mail-text">
            Ainda não recebeu <br></br> correspondência digital.
          </div>
        </div>
      </main>
    );
  }

  render() {
    let contents = "";

    if (this.state.loading) {
      contents = (
        <div className="main-page-loading">
          <div className="loading">
            <img src={require("../../images/loading.svg")}></img>
          </div>
        </div>
      );
    } else if (this.state.error && !this.state.noContent) {
      contents = this.errorLoading();
    } else if (this.state.mailList.length) {
      contents = this.renderListagemCorreio(this.state.mailList);
    } else if (this.state.noContent) {
      contents = this.noContent();
    } else {
      contents = this.renderListagemCorreioVazia();
    }

    return <div>{contents}</div>;
  }

  async populateData() {
    await new Promise((r) => setTimeout(r, 1));
    var idToken = this.context.idToken;

    var clientId = this.context.selectedUserID;

    const headers = {
      Authorization: `Bearer ${idToken}`,
      clientId: clientId,
    };

    await fetch("/Document/GetDocument?ClientId=" + clientId, {
      headers,
    })
      .then((response) => {
        if (response.status != "200") {
          this.setState({
            error: true,
            loading: false,
          });

          if (response.status === 204) {
            this.setState({
              noContent: true,
              loading: false,
            });
              sendLogError("No content!", "populateData");
          }
        }

        return response.json();
      })
      .then((response) => {
        // console.log("response GetDocument ", response);

        this.setState({
          mailList: response,
          error: false,
          loading: false,
        });
      })
      .catch((error) => {
        //console.log("error GetDocument ", error);

        this.setState({ noContent: true, loading: false });
        
      });

    //const response = await fetch("/Document/GetDocument", { headers });

    //if (response.status != "200") {
    //    this.setState({ error: true, loading: false });
    //    if (response.status === 204) {
    //        this.setState({ noContent: true, loading: false });
    //        appInsights.trackException({ exception: "CorreioDigital no content", severityLevel: 3 });
    //    }
    //} else {
    //    const data = await response.json();
    //    this.setState({
    //        mailList: data,
    //        error: false,
    //        loading: false
    //    });
    //}
  }

  async markDocumentAsRead(rowInfo, state) {
    try {
      var idToken = this.context.idToken;
      const headers = {
        Authorization: `Bearer ${idToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const response = await fetch("/Document/MarkDocumentAsRead", {
        headers,
        method: "POST",
        body: JSON.stringify({
          DocumentId: rowInfo.original.documentCode,
          client_id: rowInfo.original.client_id,
          associate_number: rowInfo.original.associate_number,
          associate_control_digit: rowInfo.original.associate_control_digit,
        }),
      });
      const data = await response.json();
      if (data === true) {
        this.updateTableReadState(rowInfo, state);
      }
    } catch (error) {
        console.error(error);
        sendLogError(error.message, "markDocumentAsRead");
    }
  }

  async getMailContent(documentDate, id, documentClass) {
    this.setState({ loadingDocument: true });
    let requestUrl =
      "/document/GetDocumentContent?documentDate=" +
      documentDate +
      "&id=" +
      id +
      "&documentClass=" +
      documentClass;
    var idToken = this.context.idToken;
    const headers = { Authorization: `Bearer ${idToken}` };
    const response = await fetch(requestUrl, { headers });

    if (response.status != "200") {
      this.setState({ hasData: false });
    } else {
      let responseData = await response.text();

      let elementId = "downloadMail" + id;
      document.getElementById(elementId).href =
        "data:application/pdf;base64, " + encodeURI(responseData);
      document.getElementById(elementId).setAttribute("downloadControl", "");
      document.getElementById(elementId).click();
      this.setState({ loadingDocument: false });
    }
  }
}

Correio.contextType = ContextConstructor;

export default withAITracking(reactPlugin, Correio);
