/* COMPONENT IMPORTS*/
import authentication from "../../msalb2creact";

/* MODULE IMPORTS */
import { createBrowserHistory } from "history";
import { withRouter } from "react-router";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { Component } from "react";
import ContextConstructor from "../../components/Context/Context";
import sendLogError from "../../sendLogError";
/* CSS IMPORT */
import "./ProtectionSubs.css";

/* VARIABLES */
const browserHistory = createBrowserHistory({ basename: "Savings Page" });
var reactPlugin = new ReactPlugin();

class ProtectionSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productCode: 7170,
      productSerie: 0,
      productTitle: "",
      capital: "Capital Subscrito",
      entrega: "Entrega Periódica",
    };
  }

  componentDidMount() {
    if (
      this.context.selectedUserID !== "" &&
      this.context.selectedUserID !== undefined &&
      this.context.selectedUserID !== null
    ) {
      this.populateMpeoData();
      this.ProtectionCards();
      // console.log("ProtectionSubscription ", this.context);
    }
  }

  createSubscription() {
    var item = {
      productID: this.state.productCode + this.state.productSerie,
      productName: this.productTitle,
      productGroup: null,
      subscriptions: null,
      totalAmounts: null,
    };

    var data = {
      productGroup: null,
      productID: this.productCode,
      productName: this.productTitle,
      subscriptionID: null,
      subscriptionSerieID: null,
      subscriptions: null,
      subscriptionsNumber: 0,
      totalAmounts: null,
      canReapply: false,
      canReinforce: false,
      /**startDateBFTeaser: null,
            endDateBFTeaser: null,
            startDateBF: null,
            endDateBF: null,
            actualDate: null**/
    };
    this.props.history.push({
      pathname: "/subscricao",
      state: { params: data, modalidade: item },
    });
  }

  ProtectionCards() {
    let products = this.props.protectionList.productTypes;
    let isCoincidence = false;

    products.forEach((productElement) => {
      if (productElement["productID"] == "7170") {
        isCoincidence = true;
      }
    });

    if (isCoincidence === false && this.context.tokenUser !== this.context.selectedUserID) {
      return null;
    } else if (isCoincidence === false && this.context.tokenUser === this.context.selectedUserID) {
      //descomentar para ativar botão de subscrição
      let content = null; /*(<div className="card-new" style={{ "margin-top": "5%" }}>*/
      {
        /*<table className="card-new-table">*/
      }
      {
        /*    <tbody>*/
      }
      {
        /*        <tr>*/
      }
      {
        /*            <th class="card-new-title" style={{ "padding": "10px 5px 10px 32px", "width": "260px" }}>*/
      }
      {
        /*                <main>*/
      }
      {
        /*                    <div class="serie-name-wrapper">*/
      }
      {
        /*                        <div class="tableCapitalCerto-title-inactive">{this.state.productTitle}</div>*/
      }
      {
        /*                    </div>*/
      }
      {
        /*                </main>*/
      }
      {
        /*            </th>*/
      }
      {
        /*            <th style={{*/
      }
      {
        /*                "width": "155px",*/
      }
      {
        /*                "paddingLeft": "20px;",*/
      }
      {
        /*                "margin-left": "10px;"*/
      }
      {
        /*            }}>*/
      }
      {
        /*                <div className="card-new-table-value">0 €</div>*/
      }
      {
        /*                <div className="tableCapitalCerto-subtitle-unactive">{this.state.capital}</div>*/
      }
      {
        /*            </th>*/
      }
      {
        /*            <th style={{ "width": "110px", "paddingLeft": "10px", }} >*/
      }
      {
        /*                <div className="tableCapitalCerto-boldvalue">-</div>*/
      }
      {
        /*                <div className="tableCapitalCerto-subtitle-unactive">{this.state.entrega}</div>*/
      }
      {
        /*            </th>*/
      }
      {
        /*            <th>*/
      }
      {
        /*                <div className="create-protection-button"*/
      }
      {
        /*                    onClick={() => this.createSubscription()}>*/
      }
      {
        /*                    <img src={require('../../images/newseries.png')} style={{ "cursor": "pointer", "marginLeft": "-60%" }} height="50px" width="50px"></img>*/
      }
      {
        /*                </div>*/
      }
      {
        /*            </th>*/
      }
      {
        /*        </tr>*/
      }
      {
        /*    </tbody>*/
      }
      {
        /*</table>*/
      }
      //</div>);

      return content;
    }

    //let content = isCoincidence === true ? "" : (
    //    <div className="card-new" style={{ "margin-top": "5%" }}>
    //        <table className="card-new-table">
    //            <tbody>
    //                <tr>
    //                    <th class="card-new-title" style={{ "padding": "10px 5px 10px 32px", "width": "260px"}}>
    //                        <main>
    //                        <div class="serie-name-wrapper">
    //                                <div class="tableCapitalCerto-title-inactive">{this.state.productTitle}</div>
    //                            </div>
    //                        </main>
    //                    </th>
    //                    <th style={{
    //                        "width": "155px",
    //                        "paddingLeft": "20px;",
    //                        "margin-left": "10px;"
    //                    }}>
    //                        <div className="card-new-table-value">0 €</div>
    //                        <div className="tableCapitalCerto-subtitle-unactive">{this.state.capital}</div>
    //                    </th>
    //                    <th style={{"width": "110px", "paddingLeft": "10px", }} >
    //                        <div className="tableCapitalCerto-boldvalue">-</div>
    //                        <div className="tableCapitalCerto-subtitle-unactive">{this.state.entrega}</div>
    //                    </th>
    //                    <th>
    //                        <div className="create-protection-button"
    //                            onClick={() => this.createSubscription()}>
    //                            <img src={require('../../images/newseries.png')} style={{ "cursor": "pointer", "marginLeft": "-45%" }} height="50px" width="50px"></img>
    //                        </div>
    //                    </th>
    //                </tr>
    //            </tbody>
    //        </table>
    //    </div>
    //)
  }

  render() {
    return <React.Fragment>{this.ProtectionCards()}</React.Fragment>;
  }

  async populateMpeoData() {
    const MpoeProductCode = `${this.state.productCode}${this.state.productSerie}`;
    var idToken = this.context.idToken;

    const headers = {
      Authorization: `Bearer ${idToken}`,
    };

    //    if (response.status !== 200) {
    //        this.setState({ hasData: false });

    //    }
    //    else {
    //        const reponseData = await response.json();
    //        console.log("console data : ", reponseData, " productId : ", productID)
    //        this.setState({ data: reponseData, hasData: true });
    //    }
    //    /*console.log({ response });*/
    //}

    try {
      //await fetch('/Portfolio/GetProductDetails?productID=' + MpoeProductCode, {
      //    headers,
      //    method: 'GET',
      //})
      //    .then(response => {

      //        return response.json();
      //    })
      //    .then(res => {

      //        console.log('MPOE res: ', res);

      //        this.setState({
      //            productTitle: res.head.title.toUpperCase(),
      //        });
      //    });

      await fetch("/Portfolio/GetProduct?Code=" + this.state.productCode, {
        headers,
        method: "GET",
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          //console.log('MPOE res: ', res);

          this.setState({
            productTitle: res.description,
          });
        });
    } catch (error) {
        // console.log("MPOE error: ", error);
        sendLogError(error.message, "populateProtectionData");
    }
  }
}

ProtectionSubscription.contextType = ContextConstructor;

export default withRouter(ProtectionSubscription);
