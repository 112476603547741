import React, { Component } from 'react';
import AssociadoMenorTab from "./AssociadoMenorTab";
import AssociadoTab from "./AssociadoTab";

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: this.props.profile,
            activeTab: new URLSearchParams(window.location.search).get('activeTab') ?? "tab1"
        };
        this.handleTab1Change = this.handleTab1Change.bind(this);
        this.handleTab2Change = this.handleTab2Change.bind(this);
    }
    handleTab1Change() {
        this.setState({ activeTab: "tab1" });
    }
    handleTab2Change() {
        this.setState({ activeTab: "tab2" });
    }

    render() {
        return (
            <div className="User-Fields">
                <ul className="Same-Line-Fields">
                    <li
                        className={this.state.activeTab === "tab1" ? "active" : ""}
                        onClick={this.handleTab1Change}
                    >
                        Os meus dados
                    </li>
                    {/*<li descomentar para activar Agregado Familiar */}
                    {/*    className={this.state.activeTab === "tab2" ? "active" : ""}*/}
                    {/*    onClick={this.handleTab2Change}*/}
                    {/*>*/}
                    {/*    Dados do meu Agregado Familiar*/}
                    {/*</li>*/}
                </ul>
                <div className="outlet">
                    {/*this.state.activeTab === "tab1" ? <AssociadoTab profile={this.props.profile} /> : <AssociadoMenorTab />*/
                        <AssociadoTab profile={this.props.profile} />
                    }
                </div>
            </div>
        );
    }
};
export default Tabs;