import React, { useContext } from "react";
import { AuthContext } from "../../AuthProvider"; // Importando o AuthContext
import { LoadingPopup } from "../../components/LoadingPopup/LoadingPopup";
import sendLogError from "../../sendLogError";

const ContextConstructor = React.createContext();

export default ContextConstructor;


export class ContextProvider extends React.Component {
  static contextType = AuthContext; // Adicionando o contexto

  constructor(props) {
    super(props);

    this.state = {
      mymUsers: [],
      selectedUser: [],
      selectedUserID: "",
      tokenUser: "",
      userName: "",
      parentUser: {},
      idToken: "", // Inicializamos vazio e o valor real será definido no componentDidMount
      msalInstance: null,
      isLoggedIn: false, // Inicializamos falso e o valor real será definido no componentDidMount
      loading: true, // Estado de carregamento inicializado como true
    };
  }

  componentDidMount() {
    const { idToken, isLoggedIn, msalInstance } = this.context; // Obtendo o idToken e isLoggedIn do AuthContext

    this.setState(
      {
        idToken,
        isLoggedIn,
        msalInstance,
      },
      () => {
        this.populateAssociateData();
      },
    );
  }

  contextInit = (usersProfile, selectedUserIDClientID, isActualUser, selectedUserProfile) => {
    // console.log("selectedUserProfile ", selectedUserProfile);

    // Atribuir parâmetro às variáveis de estado
    this.setState({
      mymUsers: usersProfile,
      selectedUser: selectedUserProfile,
      selectedUserID: selectedUserIDClientID,
      tokenUser: isActualUser,
      parentUser: selectedUserProfile,
      loading: false, // Dados carregados, definir loading como false
    });
  };

  changeselectedUserID = (associate) => {
    // Alterar a variável de estado selectedUserID
    this.setState({
      selectedUserID: associate.clientID,
      selectedUser: associate,
    });
  };

  populateAssociateData = async () => {
    const { idToken } = this.state;
      // console.log("ID Token in populateAssociateData:", idToken);
      
      

    await new Promise((r) => {
      setTimeout(r, 1);
    });

    if (idToken) {
      const headers = {
        Authorization: `Bearer ${idToken}`,
      };
      const response = await fetch("/Profile/GetMymAssociates", { headers });
        
      if (response.status !== 200) {
        this.setState({ loading: false });
      } else {
        const data = await response.json();
        // Adicionar User do token dentro do Array de Associados!!!!!!

        fetch("/Profile/GetProfile", {
          method: "GET",
          headers,
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw response;
            }
          })
          .then((userProfileResponse) => {
            // console.log(userProfileResponse);

            data.mymAssociates.push(userProfileResponse);

            var selectedUserClientID = "";

            data.mymAssociates.map((associated) => {
              if (associated.clientID === userProfileResponse.clientID) {
                associated.isTokenUser = true;
                selectedUserClientID = userProfileResponse.clientID;
              } else {
                associated.isTokenUser = false;
              }
            });

            // Update the Context based on selected User to display
            this.contextInit(
              data.mymAssociates,
              selectedUserClientID,
              userProfileResponse.clientID,
              userProfileResponse,
            );
          })
          .catch((error) => {
            console.error("Error fetching profile:", error);
              this.setState({ loading: false });
              sendLogError(error.message, "populateAssociateData");
          });

        }
        
    }
  };

  render() {
    // console.log("main context ", this.state);

    // Definir variáveis de Contexto
    const {
      mymUsers,
      selectedUser,
      selectedUserID,
      tokenUser,
      parentUser,
      idToken,
      isLoggedIn,
      msalInstance,
      loading,
    } = this.state;

    // Definir funções de Contexto
    const { contextInit, changeselectedUserID } = this;

    if (loading) {
        return (
            <LoadingPopup/>
        );
    }
      
    return (
      <ContextConstructor.Provider
        value={{
          mymUsers,
          selectedUser,
          selectedUserID,
          tokenUser,
          contextInit,
          changeselectedUserID,
          parentUser,
          idToken,
          isLoggedIn,
          msalInstance,
        }}>
        {this.props.children}
      </ContextConstructor.Provider>
    );
  }
}
